








































































































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { SessionWrapper },
  data() {
    return {
      now: new Date(),
      form: {
        title: '',
        name: localStorage.getItem('username') || '',
      },
    };
  },

  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
    async submitQuestion() {
      await this.$store.dispatch('viewer/submitQuestion', {
        ...this.form,
      });
    },
  },
});
